import React from 'react'
import PropTypes from 'prop-types'
import styled, { keyframes } from 'styled-components'

const BaseButton = styled.button`
  background-color: ${(props) => (props.disabled ? '#ffa9b7' : '#fc244c')};
  color: white;
  cursor: ${(props) => (props.disabled ? 'default' : 'pointer')};
  border: none;
  width: 100%;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
`

const rotate = keyframes`
  to {
    transform: rotate(360deg);
  }
`

const Loader = styled.div`
  border-top: 3px solid #ff7d93;
  border-left: 3px solid #ff7d93;
  border-bottom: 3px solid #ff7d93;
  border-right: 3px solid #ffa9b7;
  width: 20px;
  height: 20px;
  border-radius: 99px;
  animation: ${rotate} 2s linear infinite;
`

const Button = ({ children, ...props }) => {
  const { disabled, isLoading } = props

  return (
    <BaseButton {...props} disabled={disabled || isLoading}>
      {isLoading ? <Loader /> : children}
    </BaseButton>
  )
}

Button.propTypes = {
  children: PropTypes.any.isRequired,
  disabled: PropTypes.bool,
  isLoading: PropTypes.bool,
}

export default Button
