import React, { useEffect, useMemo } from 'react'

import { useTranslation } from 'react-i18next'
import { useSearchParams } from 'react-router-dom'

import SuccessAlert from '../components/SuccessAlert'

const Success = () => {
  const { t } = useTranslation()
  const [searchParams] = useSearchParams()

  const { action } = useMemo(
    () => ({
      action: searchParams.get('action') || 'card_enrollment',
    }),
    [searchParams],
  )

  useEffect(() => {
    if (
      window.ReactNativeWebView != null &&
      window.ReactNativeWebView.postMessage
    ) {
      setTimeout(() => {
        window.ReactNativeWebView.postMessage(
          JSON.stringify({
            success: true,
          }),
        )
      }, 1000)
    }
  }, [])

  return (
    <SuccessAlert data-testid="success-title">
      {t(action + '_success')}
    </SuccessAlert>
  )
}

export default Success
