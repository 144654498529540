{
  "3ds_loading_title": "We are contacting your bank...",
  "3ds_loading_subtitle": "This step can take up to 1 minute",
  "bank_header": "We are validating your card with your bank. You will receive a request to authorise a payment for 1€. Don't worry, this is temporary and nothing will be charged from your account. ",
  "bank_footer": "I agree to let boxy send instructions to my bank to charge this card when I make a purchase in a boxy.",
  "add_payment_method": "Add payment method",
  "use_payment_method": "Use payment method",
  "card_enrollment_success": "Payment method successfully added!",
  "bulk_payment_success": "Payment successfully done!",
  "default_error_message": "An error occurred, please try again.",
  "test_card_message": "[TEST]Test card number: 4035 5014 2814 6300 - 03/30 - 737[/TEST]",
  "store_payment_method_label": "Save this payment method in the app"
}
