{
  "3ds_loading_title": "Nous contactons ta banque...",
  "3ds_loading_subtitle": "Cette étape peut prendre jusqu'à 1 minute",
  "bank_header": "Nous allons vérifier la validité de cette carte auprès de ta banque. Rassure toi, aucun paiement ne sera effectué. Tu vas simplement recevoir une autorisation de 1€ pour valider ton compte. C'est temporaire, rien ne sera débité.",
  "bank_footer": "J’autorise Boxy à envoyer des instructions à ma banque pour effectuer un paiement à partir de cette carte chaque fois que j’effectue un achat en magasin.",
  "add_payment_method": "Ajouter ce moyen de paiement",
  "use_payment_method": "Utiliser ce moyen de paiement",
  "card_enrollment_success": "Moyen de paiement ajouté !",
  "bulk_payment_success": "Paiement effectué !",
  "default_error_message": "Une erreur est survenue, merci de réessayer.",
  "test_card_message": "[TEST]Numéro de carte de test : 4035 5014 2814 6300 - 03/30 - 737 [/TEST]",
  "store_payment_method_label": "Enregistrer ce moyen de paiement dans l'app"
}
